import { useDirectory } from "@hooks/crud/useDirectory";
import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";

import { CollaboratorTable } from "../Tables/CollaboratorTable/CollaboratorTable";

interface PermitCollaboratorsProps {
  loading: boolean;
  permit?: PermitLineItem;
  handleToggleShareCondition: (directory: DirectoryContactLineItem) => void;
}
export const PermitCollaborators = ({
  handleToggleShareCondition,
  permit,
}: PermitCollaboratorsProps): JSX.Element => {
  const { directory, isDirectoryLoading, setDirectory } = useDirectory(
    undefined,
    permit?.id
  );

  const handleToggleShareConditionWithDirectoryUpdate = async (
    directory: DirectoryContactLineItem
  ) => {
    await handleToggleShareCondition(directory);

    setDirectory((prevDirectory) =>
      prevDirectory.map((d) =>
        d.id === directory.id ? { ...d, accessLevel: "No Access" } : d
      )
    );
  };
  return (
    <CollaboratorTable
      loading={isDirectoryLoading}
      profiles={directory}
      handleToggleShareCondition={handleToggleShareConditionWithDirectoryUpdate}
    />
  );
};

interface PublicPermitCollaboratorsProps {
  loading: boolean;
  directory: DirectoryContactLineItem[];
}

export const PublicPermitCollaborators = ({
  directory,
  loading,
}: PublicPermitCollaboratorsProps): JSX.Element => {
  return <CollaboratorTable loading={loading} profiles={directory} />;
};
