import { useProfile } from "@hooks/crud/useProfile";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { UserMultiSearch } from "@stories/molecules/UserMultiSearch/UserMultiSearch";
import { useFormik } from "formik";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { useState } from "react";
import * as yup from "yup";

interface PermitAdditionalInfoFormProps {
  permit: PermitLineItem;
  handleBack: () => void;
  handleSubmit: (permit: PermitLineItem) => void;
}

export const PermitAdditionalInfoForm = ({
  handleBack,
  handleSubmit,
  permit,
}: PermitAdditionalInfoFormProps) => {
  const { searchProfiles } = useProfile();

  const [approvers, setApprovers] = useState<ProfileLineItem[]>(
    permit.approvers
  );

  const validationSchema = yup.object({
    memorandumNote: yup.string(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      approvers: permit.approvers,
      managers: permit.managers,
      memorandumNote: permit.memorandumNote || "",
    },
    onSubmit: async (values) => {
      await handleSubmit({
        ...permit,
        approvers: approvers,
        managers: values.managers,
        memorandumNote: values.memorandumNote,
      });
    },
    validationSchema: validationSchema,
  });

  const onApproverChange = (profiles?: ProfileLineItem[]) => {
    if (profiles) {
      setApprovers(profiles);
      formik.setFieldValue(
        "approvers",
        profiles.map((p) => p.id)
      );
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} mt={2}>
        <Grid item md={12}>
          <TextField
            id="memorandumNote"
            name="memorandumNote"
            label="Memorandum Note"
            rows={5}
            multiline
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.memorandumNote}
            error={
              formik.touched.memorandumNote &&
              Boolean(formik.errors.memorandumNote)
            }
            helperText={
              formik.touched.memorandumNote ? formik.errors.memorandumNote : ""
            }
          />
        </Grid>
        <Grid item md={12}>
          <UserMultiSearch
            loading={false}
            id="approvers"
            name="approvers"
            label="Invite Permit Approvers"
            existingProfiles={approvers}
            disabled={formik.isSubmitting}
            onChange={onApproverChange}
            onBlur={formik.handleBlur}
            handleSearch={searchProfiles}
            error={formik.touched.approvers && Boolean(formik.errors.approvers)}
          />
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        mt={2}
        sx={{
          display: "flex !important",
          justifyContent: "space-between",
          padding: "10px 0 0",
        }}
      >
        <StyledButton
          loading={false}
          color="primary"
          variant="outlined"
          disabled={formik.isSubmitting}
          onClick={handleBack}
        >
          Back
        </StyledButton>
        <StyledButton
          loading={false}
          color="primary"
          variant="contained"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Submit
        </StyledButton>
      </Grid>
    </form>
  );
};
