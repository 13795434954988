"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractTablesFromHtml = exports.convertWordToHtml = void 0;
var mammoth = __importStar(require("mammoth"));
var condition_1 = require("../entities/condition");
var condition_2 = require("../interfaces/condition");
var paddHTmlContent = function (htmlString) {
    return htmlString.replace(/<\/p>/g, "</p><br/>");
};
var convertWordToHtml = function (file) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve, _) {
                var reader = new FileReader();
                reader.onloadend = function () {
                    var arrayBuffer = reader.result;
                    if (arrayBuffer) {
                        mammoth
                            .convertToHtml({ arrayBuffer: arrayBuffer })
                            .then(function (resultObject) {
                            resolve(resultObject.value);
                        });
                    }
                };
                reader.readAsArrayBuffer(file);
            })];
    });
}); };
exports.convertWordToHtml = convertWordToHtml;
var extractTablesFromHtml = function (htmlString, _, __) {
    // Step 1: Parse the HTML string
    var parser = new DOMParser();
    var doc = parser.parseFromString(htmlString, "text/html");
    // Step 2: Find all tables
    var tables = doc.querySelectorAll("table > tbody");
    var bcaColumnTablesData = [];
    var dcaColumnTablesData = [];
    function stripHtml(html) {
        var div = document.createElement("div");
        div.innerHTML = html;
        return div.textContent || div.innerText || "";
    }
    // Iterate through each table
    tables.forEach(function (table, index) {
        var rows = table.querySelectorAll("tr");
        var isFiveColumnTable = true;
        // Check if every row has exactly six columns
        rows.forEach(function (row) {
            var cells = row.querySelectorAll("td");
            if (cells.length !== 5) {
                isFiveColumnTable = false;
            }
        });
        // If the table has exactly six columns, extract its data
        if (isFiveColumnTable) {
            if (index == 0) {
                bcaColumnTablesData.push(table);
            }
            else {
                dcaColumnTablesData.push(table);
            }
        }
    });
    var allTablesData = [];
    // Step 3: Iterate through each table
    bcaColumnTablesData.forEach(function (table) {
        var tableData = [];
        // Step 4: Iterate through each row
        var rows = table.querySelectorAll("tr");
        rows.forEach(function (row) {
            // if (!index) return;
            var rowData = {
                assigneeNotes: "",
                authority: condition_1.ConditionAuthority.BCA,
                conditionComments: "",
                conditionDetails: "",
                discipline: "",
                item: "",
                status: condition_2.ConditionStatus.NotAssigned,
            };
            // Step 5: Iterate through each cell
            var cells = row.querySelectorAll("td, th");
            cells.forEach(function (cell, index) {
                if (index === 0) {
                    rowData.item = cell.innerHTML;
                }
                else if (index === 1) {
                    rowData.conditionDetails = paddHTmlContent(cell.innerHTML);
                }
                else if (index === 2) {
                    rowData.conditionComments = paddHTmlContent(cell.innerHTML);
                }
                else if (index === 3) {
                    rowData.discipline = stripHtml(cell.innerHTML);
                }
                else if (index === 4) {
                    rowData.assigneeNotes = stripHtml(cell.innerHTML);
                }
            });
            // Push the row data array to the table data array
            tableData.push(rowData);
        });
        // Push the table data array to the all tables array
        allTablesData.push(tableData);
    });
    // Step 3: Iterate through each table
    dcaColumnTablesData.forEach(function (table) {
        var tableData = [];
        // Step 4: Iterate through each row
        var rows = table.querySelectorAll("tr");
        rows.forEach(function (row) {
            // if (!index) return;
            var rowData = {
                assigneeNotes: "",
                authority: condition_1.ConditionAuthority.DA,
                conditionComments: "",
                conditionDetails: "",
                discipline: "",
                item: "",
                status: condition_2.ConditionStatus.NotAssigned,
            };
            // Step 5: Iterate through each cell
            var cells = row.querySelectorAll("td, th");
            cells.forEach(function (cell, index) {
                if (index === 0) {
                    rowData.item = cell.innerHTML;
                }
                else if (index === 1) {
                    rowData.conditionDetails = paddHTmlContent(cell.innerHTML);
                }
                else if (index === 2) {
                    rowData.conditionComments = paddHTmlContent(cell.innerHTML);
                }
                else if (index === 3) {
                    rowData.discipline = stripHtml(cell.innerHTML);
                }
                else if (index === 4) {
                    rowData.assigneeNotes = stripHtml(cell.innerHTML);
                }
            });
            // Push the row data array to the table data array
            tableData.push(rowData);
        });
        // Push the table data array to the all tables array
        allTablesData.push(tableData);
    });
    return allTablesData;
};
exports.extractTablesFromHtml = extractTablesFromHtml;
