import DeleteIcon from "@mui/icons-material/Delete";
import {
  Avatar,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { ListTableRowMenu } from "@stories/molecules/ListTable/ListTableRowMenu";
import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";
import { useRef, useState } from "react";

interface CollaboratorTableRowProps {
  loading: boolean;
  selected: boolean;
  collaborator: DirectoryContactLineItem;
  handleToggleShareCondition?: (directory: DirectoryContactLineItem) => void;
}

export const CollaboratorTableRow = ({
  collaborator,
  handleToggleShareCondition,
  selected,
}: CollaboratorTableRowProps) => {
  const ref = useRef<Element>();
  const [open, setOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const menuItems = [
    {
      action: () => {
        handleClose();
        setOpenConfirmDialog(true);
      },
      color: "red",
      icon: <DeleteIcon />,
      name: "Remove Access",
    },
  ];

  const displayName = `${collaborator.firstName} ${collaborator.lastName}`;

  return (
    <>
      <TableRow tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell align="center" sx={{ width: "20%" }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {collaborator.photoUrl ? (
              <Avatar
                alt={collaborator.name}
                src={collaborator.photoUrl}
                sx={{ background: "#fff", marginRight: "10px" }}
              />
            ) : null}
            <Typography sx={{ margin: "10px" }}>{displayName}</Typography>
          </Stack>
        </TableCell>
        <TableCell align="center" sx={{ width: "20%" }}>
          {collaborator.organisationName}
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }}>
          {collaborator.accessLevel}
        </TableCell>
        <TableCell
          align="left"
          sx={{ textAlign: "left !important", width: "20%" }}
        >
          {collaborator.email}
        </TableCell>
        <TableCell
          align="center"
          sx={{ textAlign: "center !important", width: "10%" }}
        >
          {collaborator.phoneNumber}
        </TableCell>
        {handleToggleShareCondition ? (
          <TableCell align="right" sx={{ width: "5%" }} ref={ref}>
            {collaborator.organisationName == "External Collaborator" &&
            collaborator.accessLevel !== "No Access" ? (
              <IconButton
                size="large"
                color="inherit"
                onClick={() => {
                  handleClick();
                }}
              >
                <Iconify icon={"eva:more-vertical-fill"} />
              </IconButton>
            ) : null}
          </TableCell>
        ) : null}
      </TableRow>
      {handleToggleShareCondition ? (
        <>
          {ref.current && open ? (
            <ListTableRowMenu
              menuItems={menuItems}
              anchorElement={ref.current}
              handleCloseMenu={handleClose}
            />
          ) : null}
          {openConfirmDialog ? (
            <ConfirmationDialog
              message={"Are you sure you want to remove this person's access??"}
              open={openConfirmDialog}
              title={"Remove Access"}
              intent={"error"}
              onCancel={() => setOpenConfirmDialog(false)}
              onConfirm={() => {
                handleToggleShareCondition(collaborator);
                setOpenConfirmDialog(false);
              }}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};
