import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { Box, Typography } from "@mui/material";
import {
  ConditionLineItem,
  ConditionStatus,
} from "permit-one-common/src/interfaces/condition";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import React from "react";

interface ProgressTableProps {
  condition: ConditionLineItem;
  submitter: string[];
  approver: ProfileLineItem[];
  documents: ConditionDocumentLineItem[];
  comments: ConditionCommentLineItem[];
}

const ProgressTable: React.FC<ProgressTableProps> = ({
  approver,
  comments,
  condition,
  documents,
  submitter,
}) => {
  const getIcon = (status: "complete" | "in-progress" | "pending") => {
    switch (status) {
      case "complete":
        return <CheckIcon color="success" />;
      case "in-progress":
        return <CheckIcon color="action" />;
      case "pending":
        return <CloseIcon color="error" />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        border: "1px solid #ddd",
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: 600,
        p: 3,
        width: "100%",
      }}
    >
      <Typography variant="h6" component="div" sx={{ mb: 2 }}>
        Process Timeline
      </Typography>
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot>
              {getIcon(
                documents.length > 0 || comments.length > 0
                  ? "complete"
                  : "pending"
              )}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body1">{"Submit"}</Typography>
            <Typography variant="caption">
              {submitter.map((s) => s).join(", ")}
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot>
              {getIcon(
                condition.status === ConditionStatus.InReview || ConditionStatus.Approved
                  ? "complete"
                  : "pending"
              )}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body1">{"Review"}</Typography>
            <Typography variant="caption">
              {approver.length
                ? approver.map((s) => s.name).join(", ")
                : "Not Assigned"}
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot>
              {getIcon(
                condition.status === ConditionStatus.Approved
                  ? "complete"
                  : "pending"
              )}
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body1">Approved</Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  );
};

export default ProgressTable;
