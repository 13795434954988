"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderFileTypeName = exports.getFileType = void 0;
var getFileType = function (fileName) {
    var _a;
    var extension = (_a = fileName.split(".").pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    if (!extension)
        return "unknown";
    var reactImageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
    if (extension === "pdf") {
        return "pdf";
    }
    else if (reactImageExtensions.includes(extension)) {
        return "image";
    }
    return "unknown";
};
exports.getFileType = getFileType;
var renderFileTypeName = function (fileName) {
    if (!fileName || typeof fileName !== "string")
        return "";
    var trimmedFileName = fileName.trim();
    return trimmedFileName.split("/").pop() || "";
};
exports.renderFileTypeName = renderFileTypeName;
