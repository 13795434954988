import { downloadFile } from "@hooks/utils/useUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { ListTableRowMenu } from "@stories/molecules/ListTable/ListTableRowMenu";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import { conditionDisciplineToString } from "permit-one-common/src/entities/condition";
import { ConditionDiscipline } from "permit-one-common/src/entities/condition";
import { FileGenerationStatus } from "permit-one-common/src/entities/fileGeneration";
import {
  ConditionDocumentLineItem,
  documentCategoryToString,
} from "permit-one-common/src/interfaces/conditionDocument";
import { DocumentCategory } from "permit-one-common/src/interfaces/conditionDocument";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { generateDocumentName } from "permit-one-common/src/utils/string";
import { useState, useRef } from "react";

interface FileRowProps {
  project: ProjectLineItem;
  conditionDocument: ConditionDocumentLineItem;
  documentCount: number;
  index: number;
  disabled: boolean;
  canEdit: boolean;
  updateDocuments: (documents: ConditionDocumentLineItem[]) => void;
  removeFile: (document: ConditionDocumentLineItem) => void;
  viewDocument: (documentUri: string) => void;
}

export const FileRow = ({
  canEdit,
  conditionDocument,
  disabled,
  documentCount,
  project,
  removeFile,
  updateDocuments,
  viewDocument,
}: FileRowProps) => {
  const ref = useRef<Element>();
  const [open, setOpen] = useState(false);
  const [titleText, setTitleText] = useState(conditionDocument.documentTitle);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const handleChangeTitle = (event: React.FocusEvent<HTMLInputElement>) => {
    setTitleText(event.target.value);
  };

  const handleSetTitle = async () => {
    await updateDocuments([
      {
        ...conditionDocument,
        documentTitle: titleText,
      },
    ]);
  };

  const handleSetDiscipline = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    await updateDocuments([
      {
        ...conditionDocument,
        documentDiscipline: event.target.value as ConditionDiscipline,
        documentNumber: generateDocumentName(
          project?.projectName || "",
          event.target.value as ConditionDiscipline,
          conditionDocument.documentCategory,
          documentCount
        ),
      },
    ]);
  };

  const handleSetCategory = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    await updateDocuments([
      {
        ...conditionDocument,
        documentCategory: event.target.value as DocumentCategory,
        documentNumber: generateDocumentName(
          project?.projectName || "",
          conditionDocument.documentDiscipline,
          event.target.value as DocumentCategory,
          documentCount
        ),
      },
    ]);
  };

  const handleDownload = () => {
    setDownloadProgress(0);
    setOpenDownloadDialog(true);
    downloadFile(
      conditionDocument.url,
      conditionDocument.documentTitle,
      (progress: any) => {
        const total = (progress.loaded / progress.total) * 100;

        setDownloadProgress(total);
        if (total >= 100) {
          setOpenDownloadDialog(false);
        }
      }
    );
  };
  const menuItems = [
    {
      action: () => {
        handleClose();
        viewDocument(conditionDocument.url);
      },
      color: "black",
      icon: <RemoveRedEyeIcon />,
      name: "View",
    },
  ];
  if (canEdit) {
    menuItems.push({
      action: () => {
        handleClose();
        handleDownload();
      },
      color: "black",
      icon: <CloudDownloadIcon />,
      name: "Download",
    });
    menuItems.push({
      action: () => {
        handleClose();
        setOpenConfirmDialog(true);
      },
      color: "red",
      icon: <DeleteIcon />,
      name: "Delete",
    });
  }
  return (
    <TableRow>
      <TableCell>
        <Typography
          sx={{ cursor: "pointer" }}
          variant="body2"
          onClick={() => viewDocument(conditionDocument.url)}
        >
          {conditionDocument?.file?.name ||
            generateDocumentName(
              project?.projectName || "",
              conditionDocument.documentDiscipline,
              conditionDocument.documentCategory,
              documentCount
            )}
        </Typography>
      </TableCell>
      <TableCell>
        <TextField
          fullWidth
          disabled={disabled}
          id="documentTitle"
          name="documentTitle"
          value={titleText}
          onChange={handleChangeTitle}
          onBlur={handleSetTitle}
        />
      </TableCell>
      <TableCell>
        <TextField
          select
          fullWidth
          disabled={disabled}
          id="documentDiscipline"
          name="documentDiscipline"
          value={conditionDocument.documentDiscipline}
          onChange={handleSetDiscipline}
        >
          {Object.values(ConditionDiscipline).map((c, index) => (
            <MenuItem
              key={`${conditionDocument.id}-condition-document-discipline-${index}`}
              value={c}
            >
              {conditionDisciplineToString(c)}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell>
        <TextField
          select
          disabled={disabled}
          id="documentCategory"
          name="documentCategory"
          fullWidth
          value={conditionDocument.documentCategory}
          onChange={handleSetCategory}
          sx={{}}
        >
          {Object.values(DocumentCategory).map((d, index) => (
            <MenuItem
              key={`${conditionDocument.id}-document-types-${index}`}
              value={d}
            >
              {documentCategoryToString(d)}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell align="right" ref={ref}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            size="large"
            color="inherit"
            onClick={() => {
              handleClick();
            }}
          >
            <Iconify icon={"eva:more-vertical-fill"} />
          </IconButton>
        </Box>
      </TableCell>
      {ref.current && open ? (
        <ListTableRowMenu
          menuItems={menuItems}
          anchorElement={ref.current}
          handleCloseMenu={handleClose}
        />
      ) : null}
      {openConfirmDialog ? (
        <ConfirmationDialog
          message={"Do you want to delete this document?"}
          open={openConfirmDialog}
          title={"Delete document"}
          intent={"error"}
          onCancel={() => setOpenConfirmDialog(false)}
          onConfirm={() => removeFile(conditionDocument)}
        />
      ) : null}
      {openDownloadDialog ? (
        <PdfDownlodDialog
          downloadStep={FileGenerationStatus.Downloading}
          total={downloadProgress}
          title={"Downloading"}
        />
      ) : null}
    </TableRow>
  );
};
